import React, { Suspense, lazy } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

const HomePage = lazy(() => import('./pages/HomePage'));

const App: React.FC = () => {
	return (
		<BrowserRouter>
			<Suspense fallback={<></>}>
				<Switch>
					<Route path="/">
						<HomePage />
					</Route>
				</Switch>
			</Suspense>
		</BrowserRouter>
	);
};

export default App;
